import React, { Component } from 'react'
import { Link } from 'gatsby'
import '../components/bootstrap-marketing/startup';
import { graphql } from 'gatsby'
import Img from 'gatsby-image';
import { css } from '@emotion/core';
import Helmet from 'react-helmet';

import Stage from '../components/stage';

import Layout from '../components/BSLayout';
import MobileNavbar from '../components/MobileNavbar';
import DesktopNavbar from '../components/DesktopNavbar';
import FooterSection from '../components/Footer';

//images
import phoneImage from '../images/app-iphone.png';
import presence_image from '../images/presence_sample.png';

const PrimaryCTA = () => (
  <div className="block-xs-middle pb-5">
    <div className="container">
      <div className="row">
        <div className="col-sm-10 col-lg-8">
          <h1 className="block-titleData frequency">Upgrading living.</h1>
          <p className="lead mb-4">Powering up intelligent environments that cultivate connected, happy communities.</p>
          <Link to="/contact" className="btn btn-primary btn-lg">Speak with us</Link>
        </div>
      </div>
    </div>
  </div>
)

/*
const SectionManagerSell = () => {
  return (
<div className="block">
  <div className="container text-center">
    <div className="row mb-5 justify-content-center">
      <div className="col-10 col-md-8 col-lg-6">
        <h6 className="text-muted text-uppercase mb-2">Getting Started</h6>
        <h2 className="h2">Residents want more, but development sizes have only increased.</h2>
      </div>
      <div className="col-12 col-md-10 col-lg-8">
        <p style={{paddingTop: "3rem"}} className="lead">
        Today's onsite staff are challenged to do more with less.
        Residents want a personalised, convenient experience, coupled with increasing development sizes and managing a myriad of owners, renters and short stay guests. 
        This diverse group can create tension when sharing common spaces and communicating effectivily, Castellan connects all residents with onsite staff to help them work better together.
        </p>
      </div>
    </div>
  </div>
</div>

  );
}
*/

const SectionPresenceSell = (props) => (
<div className="block block-secondary app-iphone-block">
  <div className="container">
    <div className="row app-align-center">

      <div className="col-sm-5 d-none d-md-block">
        <img className="app-iphone" src={presence_image} alt="Example presence site screenshot" style={{"width": "100%"}} />
      </div>
      <div className="col-md-6 col-sm-12 ml-auto">
        <h6 className="text-muted text-uppercase">Presence</h6>
        <h2>Get online</h2> 
        <p className="lead mb-4">Enhance your buildings online presence with our simple and beautiful site system designed especially for your building.</p>
        <div className="row d-md-flex">
          <Link to="/presence" className="btn btn-primary btn-lg">Check it out</Link>
        </div>
      </div>
    </div>

  </div>
</div>
)

const SectionBookingSell = () => (
<div className="block block-inverse block-secondary app-iphone-block">
  <div className="container">
    <div className="row app-align-center">

      <div className="col-md-6 col-sm-12 ml-auto">
        <h6 className="text-muted text-uppercase">Booking</h6>
        <h2>Sharing made easy</h2>
        <p className="lead mb-4">Our online application enables seamless sharing, book facilities right from your smartphone.</p>
        <div className="row d-md-flex">
          <Link to="/booking" className="btn btn-primary btn-lg">Check it out</Link>
        </div>
      </div>
      <div className="col-sm-5 d-none d-md-block">
        <img className="app-iphone" alt="Example booking system screenshot on mobile" src={phoneImage} style={{"width": "100%"}} />
      </div>
    </div>

  </div>
</div>
)

/*
const SectionResidentSell = () => {
  return (
<div className="block block-inverse block-secondary app-code-block">
  <div className="container">
    <div className="row mb-5 app-align-center">
      <div className="col-10 col-md-8 col-lg-6">
        <h6 className="text-muted text-uppercase mb-2">Getting Started</h6>
        <h2 className="h2">One app, one amazing living experience.</h2>
      </div>
      <div className="col-12 col-md-10 col-lg-8">
        <p style={{paddingTop: "3rem"}} className="lead text-muted">
        To build a great living experience, we believe you need to start with people.
        Castellan connects residents to their building through a single, inuitive app,
        empowering them to not only to take control of their environment but allow the building itself adapt to them.
        Seamlessly share common areas, allowing secure access, tempature control, energy savings and more.
        Castellan provides residents with the experience they expect and delivers management teams the time saving results they need.
        </p>
      </div>
    </div>
  </div>
</div>

  );
}

const TestimonialSection = ({ background }) => {
      return (
<div className="block block-inverse app-high-praise">
      <Img 
      fluid={background.childImageSharp.fluid} 
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%"
        }}
      />
    <div className="container">
      <div className="row app-align-center py-3 justify-content-end">
        <div className="col-sm-7 col-md-5 py-5">
          <h6 className="text-muted text-uppercase mb-2">Amazing</h6>
          <h2 className="mb-4">“Castellan has helped streamline the booking process in 5 buildings, it's saved our staff significant amounts of time and our residents love it.”</h2>
          <p className="mb-4 text-muted">Bob dude, General Manager of Blah Living</p>
        </div>
      </div>
  </div>
</div>
);
      };

const CommunitiesSlideshow = ( {communities} ) => {
    console.log(communities);
    const slideHeight = 550;
    //let images = communities.map( com => <img key={com.name} srcSet={com.img.childImageSharp.fluid.srcSet} />);
    let images = communities.map( com => <img key={com.name} height={slideHeight} srcSet={com.img.childImageSharp.fluid.srcSet} />);
    return (
        <Carousel
            renderCenterLeftControls={() => null}
            renderCenterRightControls={() => null}
            initialSlideHeight={slideHeight}
            heightMode="max"
            autoplay={true}
        >
            {images}
        </Carousel>
    );
}

const cssLogoContainer = css`
    padding: 3.0rem;
`

const CurrentCustomersSection = () => (
    <div className="block app-ribbon py-5">
      <div className="container text-xs-center">
        <img src={ariaLiving} />
      </div>
    </div>
)

const cssLogoContainerText = css`
    transform: translateY(9px);
    background: #fff;
    display: inline-block;
    padding: 0 1rem;
`
const cssCommunitiesSlideshow = css`
    margin-top: 3rem;
`

const CurrentCommunitiesSection = ( {communities} ) => (
    <div className="block py-5">
      <div className="container text-center">
        <div className="row mb-5 justify-content-center">
          <div className="col-10 col-md-8 col-lg-6">
            <h6 className="text-muted text-uppercase mb-2">Our communities</h6>
          </div>
        </div>
      </div>
      <div className="container text-xs-center">
        <div css={cssLogoContainer} className="border-top border-bottom">
            <img src={ariaLiving} />
          </div>
        <div css={cssCommunitiesSlideshow} className="row" >
          <CommunitiesSlideshow communities={communities} />
        </div>
        </div>
    </div>
)
*/

const cssCTAButton = css`
    margin-top: 3rem;
`

const CTASection = () => (
    <div className="block py-5">
      <div className="container text-center">
        <div className="row mb-5 justify-content-center">
          <div className="col-10 col-md-8 col-lg-10">
            <h6 className="text-muted text-uppercase mb-2">Get in touch</h6>
            <h2>Get in touch to see how we can help you.</h2>
            <Link css={cssCTAButton} className="btn btn-primary btn-lg" to="/contact">Contact Us</Link>
          </div>
        </div>
      </div>
    </div>
);


const cssNavbar = css`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    font-weight: 500;
    letter-spacing: .05em;
`
const cssCTABackground = css`
    position: absolute !important;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    background: #000;
`

const HeadData = () => (
  <Helmet>
    <title>Castellan - Smart City Living</title>
    <meta name="description" content="Upgrade your apartment building with next generation smart city technology to provide your community an unparalleled luxury living experience." />
    <meta name="keywords" content="smart city, smart apartment, smart condominium, luxury living experience" />
  </Helmet>
)

class IndexPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false
    };
  }
  navToggle = () => {
    const { menuOpen } = this.state;
    this.setState({ menuOpen: !menuOpen});
  }

  render() {
    const { data} = this.props;
    const { menuOpen } = this.state;
    /*
    const communities = [
        {
            name: "Oxley+Stirling",
            img: data.os_img
        },
        {
            name: "The Melbourne",
            img: data.melbourne
        },
        {
            name: "The Drapery",
            img: data.drapery,
        },
        {
            name: "Valencia",
            img: data.valencia,
        },
    ];
    */
    return (
        <Layout>
          <HeadData />
          <div>
            <MobileNavbar />
            <Stage isOpen={menuOpen}>
              <div className="block block-inverse block-fill-height app-header" >
                <div css={cssNavbar} >
                  <DesktopNavbar onNavToggle={this.navToggle} transparent={true} />
                </div>
                <Img 
                fluid={data.primaryBackground.childImageSharp.fluid} 
                css={cssCTABackground}
                />
                <PrimaryCTA />
              </div>
              <SectionPresenceSell />
              <SectionBookingSell />
              {/*
              <TestimonialSection background={data.testimonialBackground} />
              <CurrentCommunitiesSection communities={communities} />
              */}
              <CTASection />
              <FooterSection />
            </Stage>
          </div>
        </Layout>
    );
};
}

export const query = graphql`
    query {
      primaryBackground: file(relativePath: { eq: "rooftop.jpg" }) {
        childImageSharp {
          fluid(maxWidth:3000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      testimonialBackground: file(relativePath: { eq: "luxury_building_testimonial_dark.jpg" }) {
        childImageSharp {
          fluid(maxWidth:3000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      os_img: file(relativePath: { eq: "buildings/oxley-and-stirling.jpg" }) {
        childImageSharp {
          fluid(maxHeight:550) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      melbourne: file(relativePath: { eq: "buildings/the_melbourne.jpg" }) {
        childImageSharp {
          fluid(maxHeight:550) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      drapery: file(relativePath: { eq: "buildings/thedrapery.jpg" }) {
        childImageSharp {
          fluid(maxHeight:550) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      valencia: file(relativePath: { eq: "buildings/valencia.jpg" }) {
        childImageSharp {
          fluid(maxHeight:550) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
`

export default IndexPage
